


import React, { useState } from "react"; 
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import AssessmentPage from "./AssessmentPage";

const BookingFormModal = ({ isOpen, handleClose }) => {
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState(null);
  const [shift, setShift] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState("");
  const [address, setAddress] = useState("");

  const handleBookingData = (data) => {
    setBookingData(data);
    console.log('Booking Data:', data); // Log to check the captured data
  };

  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    location: "",
    caregiverPlan: "",
    patientCondition: {
      diabetesType1: false,
      diabetesType2: false,
      hypertension: false,
      parkinson: false,
      noneOfTheAbove: false,
    },
  });

  const [isAssessmentPageVisible, setIsAssessmentPageVisible] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        patientCondition: {
          ...prev.patientCondition,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle form submission and start assessment
  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add any form validation logic here if needed
    setIsAssessmentPageVisible(true);
  };

  const handleBackToBookingForm = () => {
    setIsAssessmentPageVisible(false);
  };

  // Handle assessment completion and combine data
  const handleAssessmentComplete = (assessmentDetails) => {
    // Combine booking form data and assessment data
    const completeData = {
      ...formData,
      ...assessmentDetails,
    };

    // Generate WhatsApp message link with the complete data
    const message = `Booking Information:\n
    Name: ${completeData.name}\n
    Mobile Number: ${completeData.mobileNumber}\n
    Location: ${completeData.location}\n
    Caregiver Plan: ${completeData.caregiverPlan}\n
    Patient Condition: ${JSON.stringify(completeData.patientCondition)}\n
    Assessment Details: ${JSON.stringify(assessmentDetails)}`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER&text=${encodeURIComponent(
      message
    )}`;

    // Redirect to WhatsApp with the pre-filled message
    window.open(whatsappLink, "_blank");

    // Close the modal after sending the data
    handleClose();
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg max-h-[90vh] overflow-y-auto relative">
        <button
          onClick={handleClose}
          className="absolute top-1 right-4 text-gray-500 hover:text-gray-800 text-3xl"
        >
          &times;
        </button>
        <h2 className="text-xl text-black font-semibold mb-6 text-start">
          Fill the assessment to help us understand your care needs
        </h2>

        {/* Render the assessment page or the booking form depending on the state */}
        {isAssessmentPageVisible ? (
          <AssessmentPage 
            onAssessmentComplete={handleAssessmentComplete} 
            onBackToBookingForm={handleBackToBookingForm} 
          />
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <label className="block">
              <span className="text-lg font-medium">Name</span>
              <input
                type="text"
                name="name"
                placeholder="Enter Full Name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </label>
            <div className="flex items-center justify-start space-x-5">
              <label className="block">
                <span className="text-lg font-medium">Mobile Number</span>
                <input
                  type="tel"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  pattern="[0-9]{10}" // Enforces exactly 10 digits
                  maxLength={10} // Limits to 10 characters
                  className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </label>

              <label className="block">
                <span className="text-lg font-medium">Location</span>
                <select
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Select Location</option>
                  <option value="bengaluru">Bengaluru</option>
                  <option value="hyderabad">Hyderabad</option>
                  <option value="chennai">Chennai</option>
                </select>
              </label>
            </div>

            <label className="block">
              <span className="text-lg font-medium">Caregiver Plan</span>
              <select
                name="caregiverPlan"
                value={formData.caregiverPlan}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="">Select Caregiver Plan</option>
                <option value="12 Hours">12 Hours</option>
                <option value="24 Hours">24 Hours</option>
                <option value="36 Hours">36 Hours</option>
              </select>
            </label>

            <label className="block">
              <span className="text-lg font-medium">Patient Condition</span>
              <select
                name="patientCondition"
                value={formData.patientCondition}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              >
                <option value="">Select Patient Condition</option>
                <option value="Elderly Care">Elderly Care</option>
                <option value="Bed Ridden">Bed Ridden</option>
                <option value="Dementia Care">Dementia Care</option>
                <option value="Stroke Care">Stroke Care</option>
                <option value="Parkinson">Parkinson</option>
                <option value="Post-Operative Care">Post-Operative Care</option>
                <option value="Physiotherapy">Physiotherapy</option>
                <option value="None of the Above">None of the Above</option>
              </select>
            </label>

            <button
              type="submit"
              className="w-full bg-blue-500 text-lg text-white py-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Start Assessment
            </button>
          </form>
        )}
      </div>
    </div>,
    document.body
  );
};

export default BookingFormModal;
