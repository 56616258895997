import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import api from "../api";

// A protected route component
const ProtectedRoute = ({ children }) => {
  const { user, authToken } = useAuth();


  useEffect(() => {
    const fetchProtectedData = async () => {
      try {
        const response = await api.post('/api/orders', {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Failed to fetch protected data');
        }
  
        const data = await response.json();
        console.log('Protected data:', data);
      } catch (error) {
        console.error('Error fetching protected data:', error);
      }
    };

    if (user) {
      fetchProtectedData();
    }
  }, [user, authToken]);

  // If the user is not logged in, redirect to the login page
  if (!user) {
    return <Navigate to="/loginpage" />;
  }

  // If logged in, render the children (the checkout page)
  return children;
};

export default ProtectedRoute;
