import React, { useState, useContext, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import logo from "../assests/images/carelogo.png"; // Updated path
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faUser,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import BookingFormModal from "../components/BookingFormModal";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

const AnimatedBorderButton = ({ text, subtext }) => {
  return (
    <button className="relative inline-block text-blue-800 border-2 border-transparent rounded-lg p-2 overflow-hidden group">
      <span className="absolute inset-0 border-2 border-blue-500 rounded-lg transform translate-x-[-100%] transition-transform duration-300 ease-in-out group-hover:translate-x-0"></span>
      <span className="relative z-10 transition-colors duration-300 ease-in-out group-hover:text-blue-500">
      <div className="text-md">{text}</div>
{subtext && <div className="text-md md:text-md ">{subtext}</div>}

        {/* Optional subtitle */}
      </span>
    </button>
  );
};

const Navbar = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State for mobile menu
  const { user, isLoggedIn, logout } = useContext(AuthContext); // Using `AuthContext` for authentication data
  const [isHovered, setIsHovered] = useState(false);
  const containerRef = useRef(null);
  const [isHomeCareHovered, setIsHomeCareHovered] = useState(false);
  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [isHomeCareDropdownOpen, setHomeCareDropdownOpen] = useState(false);
  const homeCareRef = useRef(null);

  // Handlers for Home Care Services dropdown
  const handleHomeCareMouseEnter = () => setIsHomeCareHovered(true);
  const handleHomeCareMouseLeave = () => {
    setTimeout(() => {
      if (!homeCareRef.current.contains(document.activeElement)) {
        setIsHomeCareHovered(false);
      }
    }, 1500);
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => {
    // Use a timeout to delay closing to allow for smooth transition
    setTimeout(() => {
      if (!containerRef.current.contains(document.activeElement)) {
        setIsHovered(false);
      }
    }, 1900);
  };

  const navigate = useNavigate();

  const locations = ["Bengaluru", "Hyderabad"];

  // const handleLocationChange = (event) => {
  //   setSelectedLocation(event.target.value);
  // };

  const handleLocationChange = (event) => {
    const selectedCity = event.target.value;
    setSelectedLocation(selectedCity);
    if (selectedCity === "Bengaluru") {
      navigate("/services"); // Navigate to Services page for Bengaluru
    } else if (selectedCity === "Hyderabad") {
      navigate("/hyderabadServices"); // Navigate to HyderabadServices page for Hyderabad
    }
  };

  // const openModal = () => {
  //   if (isLoggedIn) {
  //     setIsModalOpen(true);
  //   } else {
  //     const userConfirmed = window.confirm(
  //       "You need to log in to book an appointment. Would you like to go to the login page?"
  //     );
  //     if (userConfirmed) {
  //       window.location.href = "/loginpage"; // Redirect to the login page
  //     }
  //   }
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const openModal = () => {
    setIsModalOpen(true); // Open modal directly
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  const handleLogout = () => {
    logout(); // Call logout function from AuthContext
    navigate("/loginpage"); // Redirect to login page after logout
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav
      className="font-regular bg-transparent bg-gradient-to-r from-blue-50 to-blue-200 text-blue-800 top-0 w-full z-10 p-4 md:p-5 "
      style={{ letterSpacing: "0.1em" }}
    >

      {/* contact information on the top of the screen */}
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4 py-2 h-auto">
        <div className="flex items-center space-x-4 mb-2 md:mb-0">
          <EmailIcon className="hidden md:block" />
          <span className="text-sm md:text-base font-bold">
            <a
              href="mailto:enquiry@darshanelderscare.com"
              className="text-blue-500 hover:underline"
            >
              enquiry@darshanelderscare.com
            </a>
          </span>
        </div>
        <div className="flex items-center space-x-4 mb-2 md:mb-0">
          <CallIcon className="hidden md:block" />
          <span className="text-sm md:text-base font-bold">
            <a
              href="tel:+918095006403"
              className="text-blue-500 hover:underline"
            >
              +91 80950 06403
            </a>
          </span>
        </div>
        <div className="hidden sm:flex items-center space-x-4">
          <a
            href="https://www.facebook.com/darshanelder"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg hover:text-gray-300"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://x.com/darshan_home"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg hover:text-gray-300"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.linkedin.com/company/34628898"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg hover:text-gray-300"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://www.instagram.com/darshanelderscare/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg hover:text-gray-300"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
{/* 
      <div className="container mx-auto flex items-center justify-between px-4 h-16">

        <div className="flex items-center space-x-4">
          <Link to="/" className="flex items-center justify-center">
            <img src={logo} alt="Logo" loading="lazy" className=" md:w-48 h-28 md:h-36" />
          </Link>
          <div className="relative hidden md:flex items-center">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="text-blue-800 h-6 w-6"
            />
            <div className="relative ml-2">
              <select
                className="border border-blue-800 rounded-md text-sm p-1 font-bold"
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                <option value="">Select City</option>
                {locations.map((location, index) => (
                  <option key={index} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="md:hidden flex items-center">
          <button onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <FontAwesomeIcon
                icon={faTimes}
                className="text-blue-800 h-6 w-6"
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="text-blue-800 h-6 w-6"
              />
            )}
          </button>
        </div>

    
        <div className="hidden md:flex items-center gap-4 lg:gap-4 space-x-4">
          <div>
       
            <div
              ref={homeCareRef}
              className="relative mt-6"
              onMouseEnter={handleHomeCareMouseEnter}
              onMouseLeave={handleHomeCareMouseLeave}
            >
              <Link to="/" className="text-blue-800 ">
                <AnimatedBorderButton text="Home Care" subtext="Services" />
              </Link>

              {isHomeCareHovered && (
                <div
                  className="absolute left-0 mt-2 w-[250px] flex flex-col shadow-lg rounded-lg bg-white text-xs z-50 opacity-100 transition-opacity duration-300"
                  style={{ left: `-${80}px`, borderRadius: "12px" }} // Adjust position if needed
                >
                  <Link
                    to="/elderly-care"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    Elders Care
                  </Link>
                  <Link
                    to="/post-operative-care"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    Post Operative Care
                  </Link>
                  <Link
                    to="/nurse-at-home"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    Nursing Services
                  </Link>
                  <Link
                    to="/on-call-nurse"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    On Call Nurse
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div
            ref={containerRef}
            className="relative inline-block"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Link to="/services" className="text-blue-800 block mt-6">
              <div className="block">
                <AnimatedBorderButton text="Services" />
              </div>
              <span className="opacity-0 block">Instruments</span>
            </Link>

            {isHovered && (
              <div
                className="absolute left-1 top-full mt-2 w-[200px] flex flex-col shadow-lg bg-white text-xs max-w-screen-sm z-50 opacity-100 transition-opacity duration-300"
                style={{ left: `-${22}px` }} // Adjust position if needed
              >
                <Link
                  to="/doctors-at-home"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Doctor Consultation
                </Link>
                <Link
                  to="/assisted-living"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Assistant Living
                </Link>
                <Link
                  to="/medical-equipment"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Medical Equipment
                </Link>
                <Link
                  to="/lab-services"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Lab Services
                </Link>
                <Link
                  to="/physiotherapy"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Physio Therapy
                </Link>
              </div>
            )}
          </div>
          <Link to="/about-us" className="text-blue-800">
            <AnimatedBorderButton text="About" />
          </Link>
          <Link to="/contact-us" className="text-blue-800">
            <AnimatedBorderButton text="Contact" />
          </Link>
          <Link to="/blog" className="text-blue-800">
            <AnimatedBorderButton text="Blog" />
          </Link>
        </div>



 
        <div className="hidden md:flex items-center space-x-4">
          {isLoggedIn ? (
            <div className="relative group">
              <FontAwesomeIcon
                icon={faUser}
                className="text-blue-800 h-6 w-6 cursor-pointer"
              />
              <div className="absolute right-0 mt-1 w-48 bg-white text-gray-800 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-50">
                <div className="p-2 hover:bg-gray-100">
                  <p className="text-sm font-bold">
                    Hello, ${user.fullname}
                  </p>

          
                </div>
                <Link
                  to="/profile"
                  className="block px-4 py-2 text-sm text-blue-800 hover:bg-gray-100"
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-sm text-blue-800 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            </div>
          ) : (
            <Link to="/loginpage" className="text-blue-800">
              Login
            </Link>
          )}
          <button
            onClick={openModal}
            className="bg-blue-800 text-white px-3 py-1 rounded-md hover:text-blue-400"
          >
            Book Now
          </button>
        </div>
      </div> */}


<div className="container mx-auto flex items-center justify-between px-2 md:px-4 h-16 max-w-full">
  <div className="flex items-center space-x-1">
    <Link to="/" className="flex items-center justify-center">
      <img src={logo} alt="Logo" loading="lazy" className="w-28 md:w-48 h-16 md:h-28" />
    </Link>
    <div className="relative  items-center">
      <FontAwesomeIcon icon={faMapMarkerAlt} className="text-blue-800 h-6 w-6" />
      <div className="relative ml-2">
        <select
          className="border border-blue-800 rounded-md text-sm p-1 font-bold"
          value={selectedLocation}
          onChange={handleLocationChange}
        >
          <option value="">Select City</option>
          {locations.map((location, index) => (
            <option key={index} value={location}>
              {location}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>

  {/* Mobile Menu Toggle Button */}
  <div className="md:hidden flex items-center">
    <button onClick={toggleMobileMenu}>
      {isMobileMenuOpen ? (
        <FontAwesomeIcon icon={faTimes} className="text-blue-800 h-6 w-6" />
      ) : (
        <FontAwesomeIcon icon={faBars} className="text-blue-800 h-6 w-6" />
      )}
    </button>
  </div>

  {/* Desktop Navigation Links */}
  <div className="hidden md:flex items-center gap-2 lg:gap-10 space-x-2 md:space-x-1">
    <div ref={homeCareRef} className="relative mt-6" onMouseEnter={handleHomeCareMouseEnter} onMouseLeave={handleHomeCareMouseLeave}>
      <Link to="/" className="text-blue-800 ">
        <AnimatedBorderButton text="Home Care" subtext="Services" />
      </Link>
      {isHomeCareHovered && (
                <div
                  className="absolute left-0 mt-0 w-[250px] flex flex-col shadow-lg rounded-lg bg-white text-xs z-50 opacity-100 transition-opacity duration-300 lg:mt-2"
                  style={{ left: `-${80}px`, borderRadius: "12px" }} // Adjust position if needed
                >
                  <Link
                    to="/elderly-care"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    Elders Care
                  </Link>
                  <Link
                    to="/post-operative-care"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    Post Operative Care
                  </Link>
                  <Link
                    to="/nurse-at-home"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    Nursing Services
                  </Link>
                  <Link
                    to="/on-call-nurse"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400 bg-white"
                  >
                    On Call Nurse
                  </Link>
                </div>
              )}
    </div>
    <div ref={containerRef} className="relative inline-block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
    <Link to="/services" className="text-blue-800 block">
              <div className="block">
                <AnimatedBorderButton text="Services" />
              </div>
              <span className="opacity-0 block  lg:hidden">Instruments</span>
            </Link>
      {isHovered && (
              <div
                className="absolute left-1 top-full mt-8 w-[200px] flex flex-col shadow-lg bg-white text-xs max-w-screen-sm z-50 opacity-100 transition-opacity duration-300 lg:mt-8"
                style={{ left: `-${22}px` }} // Adjust position if needed
              >
                <Link
                  to="/doctors-at-home"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Doctor Consultation
                </Link>
                <Link
                  to="/assisted-living"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Assistant Living
                </Link>
                <Link
                  to="/medical-equipment"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Medical Equipment
                </Link>
                <Link
                  to="/lab-services"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Lab Services
                </Link>
                <Link
                  to="/physiotherapy"
                  className="px-4 py-2 text-blue-800 hover:text-blue-400"
                >
                  Physio Therapy
                </Link>
              </div>
            )}
    </div>
    <Link to="/about-us" className="text-blue-800">
      <AnimatedBorderButton text="About" />
    </Link>
    <Link to="/contact-us" className="text-blue-800">
      <AnimatedBorderButton text="Contact" />
    </Link>
    <Link to="/blog" className="text-blue-800">
      <AnimatedBorderButton text="Blog" />
    </Link>
  </div>

  {/* User Authentication and Booking Button for Desktop */}
  <div className="hidden md:flex items-center space-x-2 md:space-x-4">
    {isLoggedIn ? (
      <div className="relative group">
        <FontAwesomeIcon icon={faUser} className="text-blue-800 h-6 w-6 cursor-pointer" />
        <div className="absolute right-0 mt-1 w-48 bg-white text-gray-800 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-50">
          <div className="p-2 hover:bg-gray-100">
            <p className="text-sm font-bold">Hello, ${user.fullname}</p>
          </div>
          <Link to="/profile" className="block px-4 py-2 text-sm text-blue-800 hover:bg-gray-100">
            Profile
          </Link>
          <button onClick={handleLogout} className="w-full text-left px-4 py-2 text-sm text-blue-800 hover:bg-gray-100">
            Logout
          </button>
        </div>
      </div>
    ) : (
      <Link to="/loginpage" className="text-blue-800">
        Login
      </Link>
    )}
    <button onClick={openModal} className="bg-blue-800 text-white px-3 py-1 rounded-md hover:text-blue-400">
      Book Now
    </button>
  </div>
</div>


      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden  absolute top-46 left-0 right-0 bg-white shadow-md py-4 px-6 z-20">
          <div className="flex flex-col space-y-4">
            {/* Home Care Services Dropdown */}
            <div className="w-full text-center">
              <button
                onClick={() => setHomeCareDropdownOpen(!isHomeCareDropdownOpen)}
                className="flex justify-center items-center w-full text-blue-800 hover:text-blue-400"
              >
                <Link to="/">Home Care Services</Link>
                <span>{isHomeCareDropdownOpen ? "▲" : "▼"}</span>
              </button>
              {isHomeCareDropdownOpen && !isServicesDropdownOpen && (
                <div className="ml-4 mt-2 space-y-2 ">
                  <Link
                    to="/elderly-care"
                    className="block text-blue-800 hover:text-blue-400 border "
                    onClick={toggleMobileMenu}
                  >
                    Elderly Care
                  </Link>
                  <Link
                    to="/post-operative-care"
                    className="block text-blue-800 hover:text-blue-400 border"
                    onClick={toggleMobileMenu}
                  >
                    Post Operative Care
                  </Link>
                  <Link
                    to="/nurse-at-home"
                    className="block text-blue-800 hover:text-blue-400 border"
                    onClick={toggleMobileMenu}
                  >
                    Nursing Services
                  </Link>
                  <Link
                    to="/on-call-nurse"
                    className="block text-blue-800 hover:text-blue-400 border"
                    onClick={toggleMobileMenu}
                  >
                    On Call Nurse
                  </Link>
                </div>
              )}
            </div>

            {/* About */}
            <Link
              to="/about-us"
              className="text-blue-800 hover:text-blue-400"
              onClick={toggleMobileMenu}
            >
              About
            </Link>

            {/* Services Dropdown */}
            <div className="w-full text-center">
              <button
                onClick={() => setServicesDropdownOpen(!isServicesDropdownOpen)}
                className="flex justify-center items-center w-full text-blue-800 hover:text-blue-400"
              >
                <Link to="/services">Services</Link>
                <span className="ml-2">
                  {isServicesDropdownOpen ? "▲" : "▼"}
                </span>
              </button>
              {isServicesDropdownOpen && !isHomeCareDropdownOpen && (
                <div className="ml-4 mt-2 space-y-2">
                  <Link
                    to="/doctors-at-home"
                    className="block text-blue-800 hover:text-blue-400 border"
                    onClick={toggleMobileMenu}
                  >
                    Doctor Consultation
                  </Link>
                  <Link
                    to="/assisted-living"
                    className="block text-blue-800 hover:text-blue-400 border"
                    onClick={toggleMobileMenu}
                  >
                    Assistant Living
                  </Link>
                  <Link
                    to="/medical-equipment"
                    className="block text-blue-800 hover:text-blue-400 border"
                    onClick={toggleMobileMenu}
                  >
                    Medical Equipment
                  </Link>
                  <Link
                    to="/lab-services"
                    className="block text-blue-800 hover:text-blue-400 border"
                    onClick={toggleMobileMenu}
                  >
                    Lab Services
                  </Link>
                  <Link
                    to="/physiotherapy"
                    className="px-4 py-2 text-blue-800 hover:text-blue-400"
                  >
                    Physio Therapy
                  </Link>
                </div>
              )}
            </div>

            {/* Contact */}
            <Link
              to="/contact-us"
              className="text-blue-800 hover:text-blue-400"
              onClick={toggleMobileMenu}
            >
              Contact
            </Link>

            {/* Blog */}
            <Link
              to="/blog"
              className="text-blue-800 hover:text-blue-400"
              onClick={toggleMobileMenu}
            >
              Blog
            </Link>

            {/* Logged in User Options */}
            {isLoggedIn ? (
              <div className="relative group">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-blue-800 h-6 w-6 cursor-pointer"
                />
                <div className="absolute right-0 mt-1 w-48 bg-white text-gray-800 rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-50">
                  <div className="p-2 hover:bg-gray-100">
                    <p className="text-sm font-bold">
                      {user ? `Hello, ${user.fullname}` : "User"}
                    </p>
                  </div>
                  <Link
                    to="/profile"
                    className="block px-4 py-2 text-sm text-blue-800 hover:bg-gray-100"
                  >
                    Profile
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      toggleMobileMenu();
                    }}
                    className="w-full text-left px-4 py-2 text-sm text-blue-800 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              </div>
            ) : (
              <Link
                to="/loginpage"
                className="text-blue-800 hover:text-blue-400"
                onClick={toggleMobileMenu}
              >
                Login
              </Link>
            )}

            {/* Book Now Button */}
            <button
              onClick={() => {
                openModal();
                toggleMobileMenu();
              }}
              className="bg-blue-800 text-white px-3 py-1 rounded-md hover:text-blue-400"
            >
              Book Now
            </button>
          </div>
        </div>
      )}

      {/* Booking Form Modal */}
      {isModalOpen && (
        <BookingFormModal isOpen={isModalOpen} handleClose={closeModal} />
      )}
    </nav>
  );
};

export default Navbar;
