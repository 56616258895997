import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CartProvider } from "./context/CartContext";
import React, { Suspense, lazy } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer"; // Import Footer
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { PopupProvider } from "./context/PopupContext";
import ProtectedRoute from "./context/ProtectedRoute";
import ArticlePage from "./pages/ArticlePage.js";
import ThankYouOrders from "./pages/thankYouOrders.js";

// Lazy loading components
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./pages/Services"));
const Contact = lazy(() => import("./pages/Contact"));
const Blogpage = lazy(() => import("./pages/Blog"));
const BlogPost = lazy(() => import("./pages/BlogPost"));
const PostOperativeCare = lazy(() => import("./pages/PostOperativeCare"));
const NursesLinkhome = lazy(() => import("./pages/nursesLinkhome"));
const HyderabadServices = lazy(() => import("./pages/HyderabadServices"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const Login = lazy(() => import("./pages/loginPage"));
const Signup = lazy(() => import("./pages/signup"));
const Cart = lazy(() => import("./pages/Cart"));
const Checkout = lazy(() => import("./pages/Checkout"));
const Bloodtest = lazy(() => import("./pages/Bloodtest"));
const Reports = lazy(() => import("./pages/Reports"));
const Physio = lazy(() => import("./pages/Physio"));
const Elderscare = lazy(() => import("./pages/Elderscare"));
const Oldagehome = lazy(() => import("./pages/Oldagehome"));
const Nursesathome = lazy(() => import("./pages/Nursesathome"));
const Doctorsathome = lazy(() => import("./pages/Doctorsathome"));
const Labservices = lazy(() => import("./pages/Labservices"));
const LabservicesPage = lazy(() => import("./pages/LabservicesPage"));
const Medicalequipments = lazy(() => import("./pages/Medicalequipments"));
const MedicalEquipmentCategarized = lazy(() => import("./pages/MedicalEquipmentCategarized"));
const ProductDetails = lazy(() => import("./pages/ProductDetails"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const AssessmentPage = lazy(() => import("./components/AssessmentPage"));
const NotFound = lazy(() => import("./components/NotFound.js"));
const RentalProducts = lazy(() => import("./pages/rentalproduct.js"));
const RentCart = lazy(() => import("./pages/rentcart.js"));
const RentCheckout = lazy(() => import("./pages/rentCheckout.js"));
const RentalProductDetails = lazy(() => import("./pages/rentalProductDetails.js"));

const App = () => {
  return (
    <Router>
      <CartProvider>
        <AuthProvider>
          <PopupProvider>
            <div className="App">
              <Navbar />
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about-us" element={<About />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/contact-us" element={<Contact />} />
                  <Route path="/post-operative-care" element={<PostOperativeCare />} />
                  <Route path="/nurse-at-home" element={<NursesLinkhome />} />
                  <Route path="/hyderabadservices" element={<HyderabadServices />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/blog" element={<Blogpage />} />
                  <Route path="/blog/:id" element={<BlogPost />} />
                  <Route path="/loginpage" element={<Login />} />
                  <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/bloodtest" element={<Bloodtest />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/physiotherapy" element={<Physio />} />
                  <Route path="/elderly-care" element={<Elderscare />} />
                  <Route path="/assisted-living" element={<Oldagehome />} />
                  <Route path="/on-call-nurse" element={<Nursesathome />} />
                  <Route path="/doctors-at-home" element={<Doctorsathome />} />
                  <Route path="/lab-services" element={<Labservices />} />
                  <Route path="/labservices/:title" element={<LabservicesPage />} />
                  <Route path="/medical-equipment" element={<Medicalequipments />} />
                  <Route path="/medical-equipment/:category" element={<MedicalEquipmentCategarized />} />
                  <Route path="/product/:productName" element={<ProductDetails />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/assessment" element={<AssessmentPage />} />
                  <Route path="/article/:id" element={<ArticlePage />} />
                  <Route
                      path="/rentalequipments/:category"
                      element={<RentalProducts />}
                    />
                    <Route
                      path="/rentalequipments"
                      element={<RentalProducts />}
                    />
                    <Route path="/rental/:productName" element={<RentalProductDetails />} />


                    <Route path="/rentcart" element={<RentCart />} />
                    {/* <Route path="/rentcheckout" element={<RentCheckout />} /> */}
                    <Route
                      path="/rentcheckout"
                      element={
                        <ProtectedRoute>
                          <RentCheckout />
                        </ProtectedRoute>
                      }
                    />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
              <Footer />
            </div>
          </PopupProvider>
        </AuthProvider>
      </CartProvider>
    </Router>
  );
};

export default App;
