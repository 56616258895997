import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";

const Footer = () => {
  
  return (
    <div className="bg-gradient-to-r from-blue-900 to-blue-700 text-white py-6">
      {/* Main container */}
      <div className="mx-auto px-4 w-full">
        <div className="grid grid-cols-2 md:grid-cols-3 justify-items-center">
          {/* Contact Information */}
          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mr-19 hidden sm:block">
            <h5 className="text-xl font-bold mb-4 text-center sm:text-left">
              Contact Us
            </h5>
            <address className="not-italic text-justify ">
              <div className="grid justify-items-center sm:justify-items-start text-[10px] sm:text-[18px] sm:text-start">
                <p className="mb-2">Darshan Elders Care Pvt Ltd</p>
                <p className="mb-2">#9 Gokulam 1st floor 4th cross</p>
                <p className="mb-2">Banashankari 2nd stage Bangalore 560070</p>

                <p className="mb-2 ">Phone: +91 80950 06403</p>
                <p className="mb-2">Email:enquiry@darshanelderscare.com</p>
              </div>
            </address>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 px-4 mr-19 md:hidden lg:hidden">
            <h5 className="text-xl font-bold mb-4 text-center sm:text-left">
              Contact Us
            </h5>
            <address className="not-italic">
  <div className="flex flex-col items-start text-[10px] sm:text-[18px]">
    <p className="mb-2">Darshan Elders Care Pvt Ltd</p>
    <p className="mb-2">#9 Gokulam 1st floor 4th cross</p>
    <p className="mb-2">Banashankari 2nd stage</p>
    <p className="mb-2">Bangalore 560070</p>
    <p className="mb-2">
      Phone: 
      <a href="tel:+918095006403" className="text-blue-500 hover:underline">
        +918095006403
      </a>
    </p>
    <p className="mb-2">
      Email: 
      <a href="mailto:enquiry@darshanelderscare.com" className="text-blue-500 hover:underline">
        enquiry@darshanelderscare.com
      </a>
    </p>
  </div>
</address>

          </div>

          {/* Quick Links */}
          <div className="w-full sm:w-1/2 md:w-1/3 px-4 ml-10">
            <h5 className="text-xl font-bold mb-4 sm:text-justify">
              Quick Links
            </h5>
            <ul className="space-y-2  text-justify ml-2 sm:text-justify text-[12px] sm:text-[16px]">
              <li>
                <Link
                  to="/"
                  className="hover:text-blue-300 transition-colors duration-300"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className="hover:text-blue-300 transition-colors duration-300"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="hover:text-blue-300 transition-colors duration-300"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className="hover:text-blue-300 transition-colors duration-300"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="hover:text-blue-300 transition-colors duration-300"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Media Links */}
          <div className="w-full sm:w-1/2 md:w-1/3 px-4 sm:text-justify hidden sm:block">
            <h5 className="text-xl font-bold mb-4">Follow Us</h5>
            <div className="flex justify-center sm:justify-start space-x-4 text-[10px] sm:text-[16px] mt-4">
              <a
                href="https://www.facebook.com/darshanelder"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
              >
                <FaFacebookF size={20} />
              </a>
              <a
                href="https://x.com/darshan_home"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
              >
                <FaTwitter size={20} />
              </a>
              <a
                href="https://www.linkedin.com/company/34628898"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
              >
                <FaLinkedinIn size={20} />
              </a>
              <a
                href="https://www.instagram.com/darshanelderscare/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
              >
                <FaInstagram size={20} />
              </a>
            </div>
          </div>
        </div>
        {/* follow us section for small screen */}
        <div className="w-full mt-8 sm:w-1/2 md:w-1/3 px-4 sm:text-justify md:hidden lg:hidden">
          <h5 className="text-xl font-bold mb-4">Follow Us</h5>
          <div className="flex justify-center sm:justify-start space-x-4 text-[10px] sm:text-[16px] mt-4">
            <a
              href="https://www.facebook.com/darshanelder"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
            >
              <FaFacebookF size={20} />
            </a>
            <a
              href="https://x.com/darshan_home"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
            >
              <FaTwitter size={20} />
            </a>
            <a
              href="https://www.linkedin.com/company/34628898"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
            >
              <FaLinkedinIn size={20} />
            </a>
            <a
              href="https://www.instagram.com/darshanelderscare/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-blue-400 hover:scale-110 transition-transform duration-300"
            >
              <FaInstagram size={20} />
            </a>
          </div>
        </div>

        {/* Bottom */}
        <div className="text-center border-t border-blue-600 pt-6 mt-8">
          <p className="text-sm text-gray-300">
            &copy; {new Date().getFullYear()} Darshan Elders Care Pvt Ltd. All
            rights reserved.
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default Footer;
