import React, { createContext, useContext, useState, useEffect } from "react";

// CartContext.js

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const cartData = localStorage.getItem("cart");
    try {
      return cartData ? JSON.parse(cartData) : [];
    } catch (error) {
      console.error("Failed to parse cart data:", error);
      return [];
    }
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // Function to add an item to the cart
  const addToCart = (item) => {
    setCart((prevCart) => {
      const itemIndex = prevCart.findIndex((cartItem) => cartItem.id === item.id);
      if (itemIndex > -1) {
        const newCart = [...prevCart];
        newCart[itemIndex].quantity += item.quantity;
        return newCart;
      }
      // Add basePrice to the item when adding it to the cart
      return [...prevCart, { ...item, basePrice: item.price }];
    });
  };

  // Function to update specific fields of an item (e.g., size and price)
  const updateItem = (id, updates) => {
    setCart((prevCart) =>
      prevCart.map((item) => {
        if (item.id === id) {
          let updatedItem = { ...item, ...updates };

          // If the item is "adult_diaper", adjust the price based on the selected size
          if (item.category === "Adult diaper" && updates.size) {
            let priceAdjustment = 0;
            switch (updates.size) {
              case "small":
                priceAdjustment = -20;
                break;
              case "medium":
                priceAdjustment = 0;
                break;
              case "large":
                priceAdjustment = 20;
                break;
              case "XL":
                priceAdjustment = 40;
                break;
              case "XXL":
                priceAdjustment = 60;
                break;
              default:
                priceAdjustment = 0;
                break;
            }
            updatedItem.price = item.basePrice + priceAdjustment;
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  const updateQuantity = (id, newQuantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  const removeFromCart = (id) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== id));
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        updateQuantity,
        updateItem,
        clearCart,
        removeFromCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);