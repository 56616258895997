// import React, { createContext, useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";

// // Create the AuthContext with default values
// export const AuthContext = createContext();

// // Custom hook to use AuthContext   
// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [authToken, setAuthToken] = useState(localStorage.getItem("token") || null);
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedToken = localStorage.getItem("token");
//     const storedUser = localStorage.getItem("user");
  
//     if (storedToken) {
//       setAuthToken(storedToken);
//       setIsLoggedIn(true);
//       if (storedUser) {
//         try {
//           setUser(JSON.parse(storedUser));
//         } catch (e) {
//           console.error("Failed to parse user data from localStorage:", e);
//           setUser(null);
//         }
//       }
//     }
//     setLoading(false);
//   }, []);



//    const login = async (email, password) => {
//     if (!email || !password) {
//       throw new Error("Email and password are required");
//     }
  
//     setLoading(true); // Assuming you have loading state management
//     try {
//       const response = await fetch("http://localhost:5000/api/auth/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json", // Ensure this header is set
//         },
//         body: JSON.stringify({ email, password }), // Ensure payload is correct
//       });
  
//       const data = await response.json();
  
//       // Check if the request was successful and contains the token
//       if (response.ok && data.success && data.token) {
//         // Set user details and store token in localStorage
//         setUser({
//           fullname: data.fullname,
//           email: email,
//           token: data.token,
//         });
//         localStorage.setItem("token", data.token);
//         localStorage.setItem("user", JSON.stringify({ fullname: data.fullname, email }));
  
//         setIsLoggedIn(true);
//         setAuthToken(data.token); // Assuming this sets the token globally
//         navigate("/"); // Redirect to home page after successful login
//       } else {
//         // Handle invalid login or missing token
//         throw new Error(data.message || "Invalid email or password");
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       throw error; // Pass the error up to the calling function
//     } finally {
//       setLoading(false);
//     }
//   };
  
  // const login = async (email, password) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch("http://localhost:5000/api/auth/login", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ email, password }),
  //     });
  
  //     const data = await response.json();
  //     console.log("Login Response:", data);
  
  //     if (response.ok && data.success) {
  //       setUser({
  //         fullname: data.fullname,
  //         email: email,
  //         token: data.token,
  //       });
  //       localStorage.setItem("token", data.token); // Store token in localStorage
  //       localStorage.setItem("user", JSON.stringify({ fullname: data.fullname, email }));
  //       setAuthToken(data.token); // Set the authToken from data.token
  //       setIsLoggedIn(true);
  //       setLoading(false);
  //       navigate("/"); // Redirect after successful login
  //     } else {
  //       console.error("Login failed:", data.message || "Login failed");
  //       setLoading(false);
  //       throw new Error(data.message || "Login failed");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setLoading(false);
  //     throw error;
  //   }
  // };
  

  // const login = async (email, password) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch("http://localhost:5000/api/auth/login", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ email, password }),
  //     });

  //     const data = await response.json();
  //     if (data.success) {
  //       // Store fullname and token in the context
  //       setUser({
  //         fullname: data.fullname,
  //         email: email,
  //         token: data.token, // Store token if required
  //       });
  //       localStorage.setItem("authToken", data.token);
  //       setLoading(false);
  //       navigate("/"); // Redirect to the home page
  //     } else {
  //       throw new Error("Invalid email or password");
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     throw error;
  //   }
  // };

  
  // const login = async (email, password) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch("http://localhost:5000/api/auth/login", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ email, password }),
  //     });
  
  //     const data = await response.json();
  //     console.log("Login Response:", data); // Log the response to debug
      
  //     if (response.ok && data.success) {
  //       setUser({
  //         fullname: data.fullname,
  //         email: email,
  //         token: data.token,
  //       });
  //       localStorage.setItem("token", data.token); // Ensure the token is stored correctly
  //       localStorage.setItem("user", JSON.stringify({ fullname: data.fullname, email })); // Store user details
  //       setIsLoggedIn(true);
  //       setLoading(false);
  //       navigate("/"); // Redirect after successful login
  //     } else {
  //       throw new Error(data.message || "Login failed");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setLoading(false);
  //     throw error;
  //   }
  // };

  // const login = async (email, password) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch("http://localhost:5000/api/auth/login", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ email, password }),
  //     });
  
  //     const data = await response.json();
  //     console.log("Login Response:", data); // Log the response to debug
  
  //     if (response.ok && data.success) {
  //       setUser({
  //         fullname: data.fullname,
  //         email: email,
  //         token: data.token,
  //       });
  //       localStorage.setItem("token", data.token); // Ensure the token is stored correctly
  //       localStorage.setItem("user", JSON.stringify({ fullname: data.fullname, email })); // Store user details
  //       setIsLoggedIn(true);
  //       setLoading(false);
  //       navigate("/"); // Redirect after successful login
  //     } else {
  //       throw new Error(data.message || "Login failed");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setLoading(false);
  //     throw error;
  //   }
  // };

  
  // const login = async (email, password) => {
  //   setAuthToken(token);
  //   setLoading(true);
  //   try {
  //     const response = await fetch("http://localhost:5000/api/auth/login", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ email, password }),
  //     });
  
  //     const data = await response.json();
  //     console.log("Login Response:", data);
  
  //     if (response.ok && data.success) {
  //       setUser({
  //         fullname: data.fullname,
  //         email: email,
  //         token: data.token,
  //       });
  //       localStorage.setItem("token", data.token);
  //       localStorage.setItem("user", JSON.stringify({ fullname: data.fullname, email }));
  //       setIsLoggedIn(true);
  //       setLoading(false);
  //       navigate("/"); // Redirect after successful login
  //     } else {
  //       console.error("Login failed:", data.message || "Login failed");
  //       setLoading(false);
  //       throw new Error(data.message || "Login failed");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setLoading(false);
  //     throw error;
  //   }
  // };
  
  
  // const logout = () => {
  //   localStorage.removeItem("authToken");
  //   setAuthToken(null);
  //   setUser(null);
  //   setIsLoggedIn(false);
  //   navigate("/login");
  // };

//   const logout = () => {
//     localStorage.removeItem("token"); // Use the correct key
//     setAuthToken(null);
//     setUser(null);
//     setIsLoggedIn(false);
//     navigate("/login");
//   };
  

//   return (
//     <AuthContext.Provider value={{ authToken, user, login, logout, loading, isLoggedIn }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };
//


//1st updated on 20th sept
// import React, { createContext, useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";

// // Create the AuthContext
// export const AuthContext = createContext();

// // Custom hook to use AuthContext
// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [authToken, setAuthToken] = useState(localStorage.getItem("token") || null);
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedToken = localStorage.getItem("token");
//     const storedUser = localStorage.getItem("user");
  
//     if (storedToken) {
//       setAuthToken(storedToken);
//       setIsLoggedIn(true);
//       if (storedUser) {
//         try {
//           setUser(JSON.parse(storedUser));
//         } catch (e) {
//           console.error("Failed to parse user data from localStorage:", e);
//           setUser(null);
//         }
//       }
//     }
//     setLoading(false);
//   }, []);


//   const login = async (email, password) => {
//     if (!email || !password) {
//       throw new Error("Email and password are required");
//     }
    
//     setLoading(true);
//     try {
//       const response = await fetch("http://localhost:5000/api/auth/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
      
//       const data = await response.json();
//       console.log("Login Response:", data);
   
  
//       if (response.ok && data.success && data.token) {
//         setUser({
//           id: data.userId,
//           fullname: data.fullname,
//           email: email,
//           token: data.token,
//         });
//         localStorage.setItem("token", data.token);
//         localStorage.setItem("user", JSON.stringify({ fullname: data.fullname, email }));
  
//         setIsLoggedIn(true);
//         setAuthToken(data.token);
//         navigate("/"); // Redirect to home page
//       } else {
//         // Log additional error information if login fails
//         console.error("Login failed with message:", data.message);
//         throw new Error(data.message || "Invalid email or password");
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       throw error;
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   const logout = () => {
//     localStorage.removeItem("token");
//     setAuthToken(null);
//     setUser(null);
//     setIsLoggedIn(false);
//     navigate("/login");
//   };

//   return (
//     <AuthContext.Provider value={{ authToken, user, login, logout, loading, isLoggedIn }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


//2nd updated

// import React, { createContext, useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";

// // Create the AuthContext
// export const AuthContext = createContext();

// // Custom hook to use AuthContext
// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [authToken, setAuthToken] = useState(localStorage.getItem("token") || null);
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();

//   // Fetch token and user from localStorage on app load
//   useEffect(() => {
//     const storedToken = localStorage.getItem("token");
//     const storedUser = localStorage.getItem("user");
  
//     if (storedToken) {
//       setAuthToken(storedToken);
//       setIsLoggedIn(true);
//       if (storedUser) {
//         try {
//           setUser(JSON.parse(storedUser));
//         } catch (e) {
//           console.error("Failed to parse user data from localStorage:", e);
//           setUser(null);
//         }
//       }
//     }
//     setLoading(false);
//   }, []);

//   // Login function that posts credentials and stores user and token if successful

//   const login = async (email, password) => {
//     try {
//       const response = await fetch("http://localhost:5000/api/auth/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
  
//       if (response.status === 204) {
//         throw new Error("Login successful but no content returned.");
//       }
  
//       const data = await response.json();
  
//       if (response.ok && data.token) {
//         // Handle success
//         localStorage.setItem("token", data.token);
//         localStorage.setItem("user", JSON.stringify({ id: data.userId, fullname: data.fullname }));
//         navigate("/"); // Redirect to home page
//       } else {
//         throw new Error(data.message || "Login failed");
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//     }
//   };
  
//   // const login = async (email, password) => {
//   //   if (!email || !password) {
//   //     throw new Error("Email and password are required");
//   //   }
    
//   //   setLoading(true);
//   //   try {
//   //     const response = await fetch("http://localhost:5000/api/auth/login", {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //       body: JSON.stringify({ email, password }),
//   //     });
      
//   //     const data = await response.json();
//   //     console.log("Login Response:", data);
   
//   //     if (response.ok && data.success && data.token) {
//   //       const userData = {
//   //         id: data.userId,
//   //         fullname: data.fullname,
//   //         email: email,
//   //       };

//   //       setUser(userData);
//   //       localStorage.setItem("token", data.token);
//   //       localStorage.setItem("user", JSON.stringify(userData));

//   //       setIsLoggedIn(true);
//   //       setAuthToken(data.token);
//   //       navigate("/"); // Redirect to home page
//   //     } else {
//   //       console.error("Login failed with message:", data.message);
//   //       throw new Error(data.message || "Invalid email or password");
//   //     }
//   //   } catch (error) {
//   //     console.error("Login error:", error);
//   //     throw error;
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };
  
//   // Logout function to clear localStorage and reset context state
//   const logout = () => {
//     localStorage.removeItem("token");
//     localStorage.removeItem("user");
//     setAuthToken(null);
//     setUser(null);
//     setIsLoggedIn(false);
//     navigate("/login");
//   };

//   return (
//     <AuthContext.Provider value={{ authToken, user, login, logout, loading, isLoggedIn }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


//3rd updated at 20th sep -8.25pm

// import React, { createContext, useState, useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom";

// // Create the AuthContext
// export const AuthContext = createContext();

// // Custom hook to use AuthContext
// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {
//   const [authToken, setAuthToken] = useState(localStorage.getItem("token") || null);
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const navigate = useNavigate();

//   // Fetch token and user from localStorage on app load
//   useEffect(() => {
//     const storedToken = localStorage.getItem("token");
//     const storedUser = localStorage.getItem("user");

//     if (storedToken) {
//       setAuthToken(storedToken);
//       setIsLoggedIn(true);
//       if (storedUser) {
//         try {
//           setUser(JSON.parse(storedUser));
//         } catch (e) {
//           console.error("Failed to parse user data from localStorage:", e);
//           setUser(null);
//         }
//       }
//     }
//     setLoading(false);
//   }, []);

//   // Login function that posts credentials and stores user and token if successful
//   const login = async (email, password) => {
//     if (!email || !password) {
//       throw new Error("Email and password are required");
//     }

//     setLoading(true);
//     try {
//       const response = await fetch("http://localhost:5000/api/auth/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message || "Login failed");
//       }

//       const data = await response.json();
//       console.log("Login Response:", data);

//       if (data.success && data.token) {
//         const userData = {
//           id: data.userId,
//           fullname: data.fullname,
//           email: email,
//         };

//         // Store token and user data in localStorage
//         localStorage.setItem("token", data.token);
//         localStorage.setItem("user", JSON.stringify(userData));

//         // Update state with user data
//         setUser(userData);
//         setAuthToken(data.token);
//         setIsLoggedIn(true);

//         // Optionally, send the login status to the backend API
//         await fetch("http://localhost:5000/api/auth/login", {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${data.token}`,
//           },
//           body: JSON.stringify({ userId: data.userId, loggedIn: true }),
//         });
//         console.log('User from context:', userId);
//         navigate("/"); // Redirect to home page
//       } else {
//         throw new Error("Login failed");
//       }
//     } catch (error) {
//       console.error("Login error:", error);
//       throw error;
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Logout function to clear localStorage and reset context state
//   const logout = async () => {
//     // Optionally, inform the backend that the user has logged out
//     if (authToken && user?.id) {
//       await fetch("http://localhost:5000/api/auth/updateLoggedInStatus", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${authToken}`,
//         },
//         body: JSON.stringify({ userId: user.id, loggedIn: false }),
//       });
//     }

//     // Clear token and user data from localStorage and state
//     localStorage.removeItem("token");
//     localStorage.removeItem("user");
//     setAuthToken(null);
//     setUser(null);
//     setIsLoggedIn(false);

//     navigate("/login"); // Redirect to login page
//   };

//   return (
//     <AuthContext.Provider value={{ authToken, user, login, logout, loading, isLoggedIn }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


//updated code 22nd sept 8.45pm 
import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import axios from "axios";

// Create the AuthContext
export const AuthContext = createContext();

// Custom hook to use AuthContext
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem("token") || null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // Fetch token and user from localStorage on app load
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    if (storedToken) {
      setAuthToken(storedToken);
      setIsLoggedIn(true);
      if (storedUser) {
        try {
          const userData = JSON.parse(storedUser);
          setUser(userData);
          console.log("Fetched user from localStorage:", userData);
        } catch (e) {
          console.error("Failed to parse user data from localStorage:", e);
          setUser(null);
        }
      }
    }
    setLoading(false);
  }, []);

  // Login function
  const login = async (email, password) => {
    if (!email || !password) {
      throw new Error("Email and password are required");
    }

    setLoading(true);
    try {
      const response = await axios.post("https://darshana-web-backend.el.r.appspot.com/api/auth/login", ({ email, password }));
      console.log(response,'response')

      
      const data = await response
      console.log("Login Response:", data);
      if (response.status === 200) {
        const userData = {
          id: data.userId,
          fullname: data.fullname,
          email: email,
        };
       
      
        // Store token and user data in localStorage
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(userData));

        // Update state with user data
        setUser(userData);
        console.log(userData);
        setAuthToken(data.token);
        setIsLoggedIn(true);

        navigate("/"); // Redirect to home page
      }
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = async () => {
    // Clear token and user data from localStorage and state
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setAuthToken(null);
    setUser(null);
    setIsLoggedIn(false);

    navigate("/login"); // Redirect to login page
  };

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout, loading, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};
