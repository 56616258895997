
import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";

// Utility functions for date formatting and calculations
const formatDate = (date) => {
  const options = { weekday: "long", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const calculateDateDifference = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInTime = end.getTime() - start.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.ceil(differenceInDays);
};

const questions = [
  {
    question: "Which caregiver shift will be suitable for you?",
    options: ["12 Hours", "18 Hours","24 Hours"],
    tagline:
      "Caregiver stays with you to provide care throughout the selected period",
  },
  {
    question: "How long do you need the caregiver service for?",
    options: ["7 days", "15 days", "30 days", "Custom Period"],
  },
  {
    question: "When do you want to start the caregiver service?",
    options: [],
  },
  {
    question: "Please provide your address",
    options: [],
  },
];

const AssessmentPage = (onBookingData) => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState("");

  const isCustomPeriod = answers[1] === "Custom Period";

  // Calculate today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Format the date as YYYY-MM-DD
  };

  const handleAddressChange = (event) => {
    handleAnswer(event.target.value);
  };

  // Calculate Tomorrow and Day After Tomorrow dates
  const getDates = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);

    return [
      {
        label: `Tomorrow - ${formatDate(tomorrow)}`,
        value: tomorrow.toISOString().split("T")[0], // Save as date string
      },
      {
        label: `Day After Tomorrow - ${formatDate(dayAfterTomorrow)}`,
        value: dayAfterTomorrow.toISOString().split("T")[0], // Save as date string
      },
      {
        label: `Custom Start Date (${formatDate(new Date(startDate))})`,
        value: startDate, // Save as date string from the custom period
        disabled: !isCustomPeriod, // Disable if custom period wasn't selected
      },
    ];
  };

  // Handle answering a question
  const handleAnswer = (answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentQuestion] = answer;
      return newAnswers;
    });

    // Update the start date if the current question is the third one
    if (currentQuestion === 2) {
      setStartDate(answer);
    }
  };

  // Handle date selection
  const handleDateChange = (event, type) => {
    const selectedDate = event.target.value;
    if (type === "start") {
      setStartDate(selectedDate);
      if (currentQuestion === 2) {
        handleAnswer(selectedDate); // Set the answer as the selected date
      }
    } else {
      const gapInDays = calculateDateDifference(startDate, selectedDate);
      if (gapInDays < 7) {
        setValidationError(
          "Please select an end date with at least 7 days gap from the start date."
        );
        return;
      }
      setEndDate(selectedDate);
      setValidationError(""); // Clear error if date selection is valid
    }
  };

  // Navigate to the next question
  const handleNext = () => {
    if (answers[currentQuestion]) {
      setValidationError(""); // Clear any existing validation errors
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion((prev) => prev + 1);
      }
    } else {
      setValidationError("Please fill the missing field to proceed");
    }
  };

  // Navigate to the previous question
  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion((prev) => prev - 1);
    }
  };

  // Handle form submission
  // const handleSubmit = () => {
  //   if (answers.every((answer) => answer)) {
  //     setIsSubmitted(true);
  //   } else {
  //     setValidationError("Please fill out all fields before submitting");
  //   }
  // };

  const handleSubmit = () => {
    if (answers.every((answer) => answer)) {
      const assessmentDetails = {
        shift: answers[0],
        duration: answers[1],
        startDate: answers[2],
        address: answers[3],
      };

      // Pass the captured data to the parent component
      if (typeof onBookingData === "function") {
        onBookingData(assessmentDetails);
      }
console.log(assessmentDetails);
      setIsSubmitted(true);
    } else {
      setValidationError("Please fill out all fields before submitting");
    }
  };

   // Send the assessment details to WhatsApp
   const handleWhatsAppMessage = () => {
    const assessmentDetails = {
      shift: answers[0],
      duration: answers[1],
      startDate: answers[2],
      address: answers[3],
    };

   

    const message = 
    `
      Caregiver Shift: ${assessmentDetails.shift}
      Caregiver Service Duration: ${assessmentDetails.duration}
      Service Start Date: ${assessmentDetails.startDate}
     
      Address: ${assessmentDetails.address}
    
    `.trim();
    const phoneNumber = "918095006403";
    console.log(message); 

  // WhatsApp URL
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  // Try to open the URL
  try {
    window.open(whatsappUrl, "_blank");
    console.log("WhatsApp message should open now."); // Log success
  } catch (error) {
    console.error("Error opening WhatsApp:", error); // Log any errors
  }
  navigate('/');


  // Open WhatsApp chat with the pre-filled message
  // window.open(whatsappUrl, "_blank");
  };


  // Render custom period input
  const renderCustomPeriodInput = () => (
    <div className="space-y-2">
      <p className="text-gray-600">Or select a start and end date:</p>
      <label className="block mt-4">
        From:
        <input
          type="date"
          value={startDate}
          min={getTodayDate()} // Set minimum date to today
          onChange={(e) => handleDateChange(e, "start")}
          className="border rounded p-2 w-full"
        />
      </label>
      <label className="block mt-4">
        To:
        <input
          type="date"
          value={endDate}
          min={startDate || getTodayDate()} // Set minimum end date to selected start date or today
          onChange={(e) => handleDateChange(e, "end")}
          className="border rounded p-2 w-full"
        />
      </label>

      {/* Display selected dates and number of days */}
      {startDate && endDate && (
        <p className="mt-2 text-gray-600">
          Selected Dates: {formatDate(new Date(startDate))} to{" "}
          {formatDate(new Date(endDate))} <br />
          Number of Days: {calculateDateDifference(startDate, endDate)}
        </p>
      )}
    </div>
  );

  // Render summary form upon submission
  const renderSummaryForm = () => (
    <div className="flex items-start justify-center min-h-screen">
      <div className="bg-blue-300 p-8 rounded-lg shadow-xl w-full max-w-md text-left">
        <h2 className="text-3xl font-bold mb-6 text-center">
          Assessment Completed
        </h2>
        <p className="text-gray-700 mb-4 text-center">Your Caregiver Plan </p>
        <div className="space-y-4">
          <p className="text-gray-800">Caregiver Shift: {answers[0]}</p>
          <p className="text-gray-800">
            Caregiver Service Duration: {answers[1]}
          </p>

          <p className="text-gray-800">
            Service Date:
            {isCustomPeriod && answers[2] && startDate && endDate
              ? `${formatDate(new Date(startDate))} to ${formatDate(
                  new Date(endDate)
                )} (${calculateDateDifference(startDate, endDate)} days)`
              : answers[2] || "No dates selected"}
          </p>

          <p className="text-gray-800">Address: {answers[3]}</p>
        </div>

        <div className="mt-6 text-center">
          <span>Need help right away? Book a caregiver now.</span>

          {/* <button className="bg-orange-500 text-white mt-4 py-2 px-6 rounded-lg hover:bg-orange-600 shadow-md">
            Book Now
          </button> */}
            <button
            className="bg-blue-500 text-white mt-4 py-2 px-6 rounded-lg hover:bg-orange-600 shadow-md"
            onClick={handleWhatsAppMessage}
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col justify-between  py-5">
      {isSubmitted ? (
        renderSummaryForm()
      ) : (
        <div className="max-w-3xl w-full mx-auto bg-white shadow-lg rounded-lg p-6 md:p-10">
          <div className="p-4">
            <h2 className="text-2xl font-semibold text-center text-blue-600">
              {currentQuestion + 1} of {questions.length}
            </h2>
          </div>

          <div className="p-4">
            <h3 className="text-xl mb-4 font-semibold text-gray-700">
              {questions[currentQuestion].question}
            </h3>

            {validationError && (
              <p className="text-red-500 text-center mb-4">{validationError}</p>
            )}

            {currentQuestion === 1 && answers[1] === "Custom Period" ? (
              renderCustomPeriodInput()
            ) : currentQuestion === 2 ? (
              <div>
                {getDates().map((option) => (
                  <label
                    key={option.value}
                    className={`block mb-2 ${
                      option.disabled ? "text-gray-400" : "text-gray-800"
                    }`}
                  >
                    <input
                      type="radio"
                      value={option.value}
                      checked={answers[2] === option.value}
                      onChange={(e) => handleAnswer(e.target.value)}
                      disabled={option.disabled}
                      className="mr-2"
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            ) : currentQuestion === 3 ? (
              <>
                {/* <input
                  type="text"
                  placeholder="Enter your Door/Flat no, Cross No"
                  className="border rounded p-2 mb-2 w-full"
                  onChange={handleAddressChange}
                />
                <input
                  type="text"
                  placeholder="Enter your street and area"
                  className="border rounded p-2 mb-2 w-full"
                  onChange={handleAddressChange}
                /> */}
                <input
                  type="text"
                  placeholder="Enter your full address"
                  className="border rounded p-2 mb-2 w-full"
                  onChange={handleAddressChange}
                />
              </>
            ) : (
              <div className="space-y-2">
                {questions[currentQuestion].options.map((option, index) => (
                  <label
                    key={index}
                    className="block mb-2 text-gray-700 font-medium"
                  >
                    <input
                      type="radio"
                      value={option}
                      checked={answers[currentQuestion] === option}
                      onChange={(e) => handleAnswer(e.target.value)}
                      className="mr-2"
                    />
                    {option}
                  </label>
                ))}
                {questions[currentQuestion].tagline && (
                  <p className="text-gray-600 italic">
                    {questions[currentQuestion].tagline}
                  </p>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-between mt-8">
            {currentQuestion > 0 && (
              <button
                onClick={handleBack}
                className="bg-gray-300 text-gray-800 py-2 px-6 rounded-lg hover:bg-gray-400 shadow-md"
              >
                Back
              </button>
            )}

            {currentQuestion < questions.length - 1 ? (
              <button
                onClick={handleNext}
                className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 shadow-md"
              >
                Next
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                className="bg-green-500 text-white py-2 px-6 rounded-lg hover:bg-green-600 shadow-md"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentPage;
