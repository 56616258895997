// src/pages/ArticlePage.js
import React from 'react';
import { Helmet } from 'react-helmet';

const ArticlePage = ({ article }) => {
  if (!article) {
    return <div>Loading...</div>; // Fallback for loading state or if no article data is provided
  }

  return (
    <div className="max-w-3xl mx-auto p-5">
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.description} />
        <meta name="keywords" content={article.keywords.join(', ')} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      {/* Article Title */}
      <h1 className="text-3xl font-bold mb-4">{article.title}</h1>
      
      {/* Publish Date and Author */}
      <div className="text-gray-600 mb-4">
        <span>By {article.author}</span> | <span>{new Date(article.publishDate).toLocaleDateString()}</span>
      </div>

      {/* Article Image */}
      {article.image && (
        <img
          src={article.image}
          alt={article.title}
          className="w-full h-auto mb-4 rounded"
        />
      )}

      {/* Article Content */}
      <div className="text-lg leading-relaxed mb-4" dangerouslySetInnerHTML={{ __html: article.content }} />
      
      {/* Keywords Section */}
      <div className="text-gray-600">
        <strong>Keywords:</strong> {article.keywords.join(', ')}
      </div>
    </div>
  );
};

export default ArticlePage;
