// src/api.js
import axios from 'axios';

// Create an Axios instance with default settings
const api = axios.create({
  baseURL: 'https://darshan-elders-care.el.r.appspot.com', // Update with your deployed backend URL
  timeout: 10000, // Set timeout for requests
});

// You can set up interceptors if needed, for example, to handle authentication tokens
// api.interceptors.request.use(config => {
//   const token = localStorage.getItem('token'); // or wherever you store your token
//   if (token) {
//     config.headers['Authorization'] = `Bearer ${token}`;
//   }
//   return config;
// });

export default api;
